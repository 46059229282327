import * as $ from 'jquery'

export default function() {
  $(() => {
    $('#dashboard-tabs a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      window.location.hash = (e.target as any).hash
    })

    if(window.location.hash) {
      $('#dashboard-tabs a[href="' + window.location.hash + '"]').tab('show')
    }
  })
}