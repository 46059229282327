import * as $ from 'jquery'

export default function() {
  function determineDisplay($input, $form) {
    if ($input.prop('checked')) {
      $form.hide()
    } else {
      $form.show()
    }
  }

  $(() => {
    $('[data-toggle-input]').each((_, input) => {
      const $input = $(input)
      const $form = $($input.data('toggle-input'))

      $input.on('change', () => determineDisplay($input, $form))

      determineDisplay($input, $form)
    })
  })
}
