import * as toastr from 'toastr'
import { gaSend } from '../lib/googleAnalytics'


export function ministryInterestFormInit() {
  if (window.location.search.includes('utm_')) {
    // Preserve the incoming utm_source on any links that have a utm_source, so that we can track back to the original
    // source of the user's visit.
    const incomingParams = new URLSearchParams(window.location.search)
    $('.preserve-utm').each((i, elem) => {
      const $elem = $(elem)
      const href = $elem.attr('href')
      if (href) {
        const hrefParams = new URLSearchParams(href.split('?')[1])

        // clear out the entire set of utm_ params on the button
        hrefParams.forEach((value, key) => {
          if (key.startsWith('utm_')) {
            hrefParams.delete(key)
          }
        })

        // copy over the incoming utm_ params
        incomingParams.forEach((value, key) => {
          if (key.startsWith('utm_')) {
            hrefParams.set(key, value)
          }
        })

        $elem.attr('href', `${href.split('?')[0]}?${hrefParams.toString()}`)
      }
    })
  }

  let searchParam: string | null = null

  if(window.location.search.includes('which_ministry_are_you_interested_in_starting_at_your_church_or_organization')) {
    searchParam = 'which_ministry_are_you_interested_in_starting_at_your_church_or_organization'
  } else if(window.location.search.includes('which_ministry_are_you_interested_in_starting_at_your_church')) {
    searchParam = 'which_ministry_are_you_interested_in_starting_at_your_church'
  }

  if (searchParam) {
    // Track the ministry interest form submission
    const ministry = new URLSearchParams(window.location.search).get(searchParam)
    gaSend({
      name: 'ministry_interest_form',
      args: {
        ministry: ministry,
      }
    })

    toastr.success('Thanks for your interest! We\'ll be in touch soon.')

    // Clear the ministry interest form submission params from the URL
    history.replaceState({}, '', window.location.pathname)
  }
}
