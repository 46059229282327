
export default function menuInit() {
  $('.legacy-dropdown').each((i, elem) => {
    const $elem = $(elem)
    $elem.children('a').on('click', (evt) => {
      if (!$elem.hasClass('open')) {
        // don't follow the href if the dropdown was closed.
        evt.preventDefault()

        // open the dropdown on a slight delay to prevent accidental doubleclick
        setTimeout(() => {
          $elem.addClass('open')
        }, 100)
      }
    })

    $elem.focusin((evt) => {
      // open the dropdown on tab focusin, but don't do it immediately
      // because if the focus came from user click then the 'click' handler
      // is about to fire.
      if (!$elem.hasClass('open')) {
        setTimeout(() => {
          $elem.addClass('open')
        }, 100)
      }
    })

    // close the dropdown when the focus goes to an element outside of this dropdown.
    // Wait 1 ms for the new element to actually receive focus in firefox.
    $elem.on('focusout', (evt) => {
      setTimeout(() => {
        if ($elem.has(document.activeElement).length == 0) {
          $elem.removeClass('open')
        }
      }, 1)
    })
  })
}
