import * as $ from 'jquery'

export default function resolveAccessInit() {
  const elements = document.querySelectorAll('.resolve-access')
  const resolveButtons = document.querySelectorAll('.resolve-all-button')

  if (elements.length == 0) {
    return
  }

  resolveButtons.forEach((button) => {
    $(button).on('ajax:success', function(event, data) {
      window.location.reload()
    })
  })
}
