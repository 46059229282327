
export function fileUploadInit() {
  // File Upload Input
  // -----------------
  // when a file is uploaded, grab the file name
  // and replace the text of the label
  $('.file-input-wrapper input').change(() => {
    const filename = $('.file-input-wrapper input').val().toString().split('\\').pop()
    $('.file-input-wrapper label').text(filename)
  })
}
