import * as $ from 'jquery'

export default function() {
  $(() => {
    function warningAlert(message) {
      return $('<div>')
        .addClass('alert alert-danger alert-dismissible fade show')
        .text(message)
        .delay(5000)
        .fadeOut()
    }

    function handleResponse($form, event, status, xhr) {
      // Handle backwards compat for [rails/jquery]-ujs ajax callbacks
      let json
      if (event.detail) {
        json = JSON.parse(event.detail[2].response)
        status = event.detail[1]
      } else {
        json = xhr.responseJSON
      }

      const message = (json && json.message) || 'An unexpected error occurred. Please try again later.'

      if (status == 'OK' || status == 'success') {
        $form.append($('<div>').text(message))
        $('input[type=email]', $form).val('')
        $('.alert', $form).remove()
        const w: any = window
        if (typeof w.grecaptcha != 'undefined') {
          w.grecaptcha.reset()
        }
      } else {
        $form.append(warningAlert(message))
      }
    }

    $('[data-email-capture]').each((_, input) => {
      const $form = $(input)

      $form.on('ajax:success', (event, data, status, xhr) => {
        handleResponse($form, event, status, xhr)
      })

      $form.on('ajax:error', (event, xhr, status) => {
        handleResponse($form, event, status, xhr)
      })
    })
  })
}
