import * as $ from 'jquery'
import {} from 'jquery-ujs'

import 'popper.js'
import 'bootstrap'
import '../../../vendor/assets/javascripts/modal-window'
import '../../../vendor/assets/javascripts/modernizr'

import accordionInit from '../../assets/javascripts/components/accordion'
import renderAlert from '../../assets/javascripts/components/alert'
import contactUs from '../../assets/javascripts/components/contact-us'
import emailCapture from '../../assets/javascripts/components/email-capture'
import { massInvitationModalInit } from '../../assets/javascripts/components/mass-invitation-modal'
import menuInit from '../../assets/javascripts/components/menu'
import passwordRedirect from '../../assets/javascripts/components/password-redirect'
import signInInit from '../../assets/javascripts/components/sign-in'
import toggleInput from '../../assets/javascripts/components/toggle-input'
import unmaskPasswordInit from '../../assets/javascripts/components/unmask-password'
import warnBrowser from '../../assets/javascripts/components/warn-browser'
import warnUnaffiliatedUser from '../../assets/javascripts/components/warn-unaffiliated-user'
import dashboardTabs from '../../assets/javascripts/components/dashboard-tabs'
import dashboardTable from '../../assets/javascripts/components/dashboard-table'
import resolveAccessInit from '../../assets/javascripts/components/dashboard-resolve'
import { ministryInterestFormInit } from '../../assets/javascripts/components/ministry-interest-form'
import { fitVidsInit } from '../../assets/javascripts/components/fitVids'
import { fileUploadInit } from '../../assets/javascripts/components/fileUploadInit'
import { slickInit } from '../../assets/javascripts/components/slick'
import { eraseCookie } from '../../assets/javascripts/lib/cookies'

$(() => {
  doInit(warnBrowser)
  doInit(warnUnaffiliatedUser)
  doInit(fitVidsInit)

  doInit(fileUploadInit)
  doInit(slickInit)

  doInit(unmaskPasswordInit)
  doInit(accordionInit)
  doInit(menuInit)
  doInit(signInInit)
  doInit(emailCapture)
  doInit(passwordRedirect)
  doInit(contactUs)
  doInit(toggleInput)
  doInit(renderAlert)
  doInit(massInvitationModalInit)
  doInit(dashboardTabs)
  doInit(dashboardTable)
  doInit(ministryInterestFormInit)
  doInit(resolveAccessInit)

  doInit(() => {
    $(document.body).on('click', '[data-href]', function() {
      const href = $(this).data('href')
      if (href && href.length > 0) {
        window.location = href
      }
    })
  })
  
  doInit(() => {
    $('#exit-preview').on('click', () => {
      eraseCookie('WMR_PREVIEW')
      window.location = window.location.toString()
        .replace(window.location.search, '') as any
    })
  })

  console.log('Application entrypoint loaded.')
})

function doInit(initFn: () => void) {
  try {
    initFn()
  } catch (e) {
    console.error(`failed to initialize ${initFn.name}`, e)
  }
}
