
import 'slick-carousel'

export function slickInit() {
  $('.js-slick-slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.js-slick-slider-nav',
  })
  $('.js-slick-slider-nav').slick({
    slidesToScroll: 1,
    asNavFor: '.js-slick-slider-for',
    dots: false,
    centerMode: true,
    centerPadding: '60px',
    variableWidth: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
  })
}
