// Unmask Password
// ---------------
// adds an eye icon in all inputs with id="password"
// clicking the eye toggles the visibility of the masking
// and unmasking of a typed password. default to masked
// for Unmask Password styles, check _forms.scss

// add the unmask button to all inputs with a type of
// password that do not have the .unmask-disabled class applied.
// that way we don't have to worry about adding it in markup
// all the time, and recursively add it to all past forms
export default function unmaskPasswordInit() {
  $('<span class="password-unmask"></span>').insertAfter('input[type="password"]')

  /*
    Switch actions
  */
  $('.password-unmask').on('click', function() {

    if ($(this).prev('input').attr('type') == 'password') {
      changeType($(this).prev('input'), 'text')
    } else {
      changeType($(this).prev('input'), 'password')
    }

    return false
  })
}

/*
  function from : https://gist.github.com/3559343
  Thank you bminer!
*/
// tslint:disable
function changeType(x, type) {
  if (x.prop('type') == type) {
    return x
  } // That was easy.
  try {
    return x.prop('type', type) // Stupid IE security will not allow this
  } catch (e) {
    // Try re-creating the element (yep... this sucks)
    // jQuery has no html() method for the element, so we have to put into a div first
    const html = $('<div>').append(x.clone()).html()
    const regex = /type=(\")?([^\"\s]+)(\")?/ // matches type=text or type="text"
    // If no match, we add the type attribute to the end; otherwise, we replace
    const tmp = $(html.match(regex) == null ?
      html.replace('>', ' type="' + type + '">') :
      html.replace(regex, 'type="' + type + '"'))
    // Copy data from old element
    tmp.data('type', x.data('type'))
    const events = x.data('events')
    const cb = function(events) {
      return () => {
        // Bind all prior events
        for (const i in events) {
          const y = events[i]
          for (const j in y) {
            tmp.bind(i, y[j].handler)
          }
        }
      }
    }(events)
    x.replaceWith(tmp)
    setTimeout(cb, 10) // Wait a bit to call function
    return tmp
  }
}
// tslint:enable
