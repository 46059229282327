export default function signInInit() {
  const elems = document.querySelectorAll('a[href=\'/users/sign_in\']')
  const regex = /leader-resources#/gi
  let currentUrl = window.location.href
  currentUrl = currentUrl.replace(regex, 'leader-resources/')

  Array.prototype.forEach.call(elems, (el) => {
    el.href += `?origin=${currentUrl}`
  })
}
