import DataTable from "datatables.net"
import 'datatables.net-searchbuilder'

export default function() {
  if(document.getElementById('provisional-table') && document.getElementById('partners-table')) {
    const provisionalTable = new DataTable('#provisional-table', {
      info: false,
      paging: false,
      language: {
        search: '',
        searchPlaceholder: 'Search'
      },
    })

    $('#provisional-table tbody').on('click', '.resolve-access', function(event) {
      provisionalTable.row( $(this).closest('tr') ).remove().draw()
    })

    const partnersTable = new DataTable('#partners-table', {
      info: false,
      paging: false,
      language: {
        search: '',
        searchPlaceholder: 'Search'
      },
    })

    $('#partners-table tbody').on('click', '.resolve-access', function(event) {
      partnersTable.row( $(this).closest('tr') ).remove().draw()
    })
  }
}
