import * as $ from 'jquery'
import * as toastr from 'toastr'
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
      grecaptcha: any
  }
}

const grecaptcha = window.grecaptcha

export default function() {
  $(() => {
    $('[data-contact-us]').each((_, input) => {
      const $form = $(input)

      function handleResponse(status, responseJSON) {
        if (responseJSON) {
          toastr[responseJSON.type](responseJSON.message)

          if (responseJSON.type == 'success') {
            if (typeof grecaptcha != 'undefined') {
              try {
                grecaptcha.reset()
              } catch (ex) {
                console.error(ex)
              }
            }

            $('input:visible, textarea', $form).val('')
          }
        } else {
          toastr.error('Sorry, something went wrong.')
        }
      }

      $form.on('ajax:success', (event, data, status, xhr) => {
        handleResponse(status, xhr.responseJSON)
      })

      $form.on('ajax:error', (event, xhr, status) => {
        handleResponse(status, xhr.responseJSON)
      })
    })
  })
}
