import * as toastr from 'toastr'

declare global {
  interface Window {
    showNotice(text: string)
  }
}

export default function renderAlert() {
  const body = document.getElementsByTagName('BODY')[0]
  const alertsString = body.getAttribute('data-alerts')
  if (alertsString) {
    const alerts = JSON.parse(alertsString)
    alerts.forEach((alert) => {
      const defaultType = alert[0]
      const resolvedType = defaultType.replace('alert', 'error').replace('notice', 'info')
      const alertText = alert[1]
      toastr.options.timeOut = 10000
      toastr[resolvedType](alertText)
    })
  }
}

export function showNotice(text: string) {
  toastr.info(text)
}
window.showNotice = showNotice
