
export type GA = ((...args: any[]) => void) & {
  getAll(): GATracker[]
}

export interface GATracker {
  get(name: 'name'): string
}

declare global {
  interface Window {
    ga?: GA
    dataLayer?: any[]
  }
}

/**
 * This function sends a google analytics event, but waits until google analytics is ready and a tracker has been
 * defined.
 *
 * @param event The event to send.
 *
 * Event example:
 * ```
 * name: 'ministry_interest_form',
 * args: {
 * ministry: "ministry name",
 * }
 * ```
 */
export function gaSend(event: Record<string, any>) {
  if (!window.dataLayer) return

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'event': event.name,
    ...event.args,
  })
}
