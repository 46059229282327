/**
 * On load, setup roles and initial properties
 */
export default function accordionInit(options?: { showOneAnswerAtATime?: boolean }) {// Vars
  options = options || {}
  const faqSection = $('.js-accordion')
  const faqQuestion = $('.js-accordion__question')
  const faqAnswer = $('.js-accordion__response')

  // Each FAQ Question
  faqQuestion.each(function(i) {
    $(this).attr({
      'id': 'js-accordion__question-' + i,
      'role': 'tab',
      'aria-controls': 'js-accordion__response-' + i,
      'aria-expanded': 'false',
      'aria-selected': 'false',
      'tabindex': '-1',
    })
  })

  // Each FAQ Answer
  faqAnswer.each(function(i) {
    $(this).attr({
      'id': 'js-accordion__response-' + i,
      'role': 'tabpanel',
      'aria-labelledby': 'js-accordion__question-' + i,
      'aria-hidden': 'true',
    })
  })

  // Each FAQ Section
  faqSection.each(function() {
    const $thisFaqSection = $(this)
    const thisSectionFaqQuestions = $thisFaqSection.find('.js-accordion__question')

    // Set section attributes
    $thisFaqSection.attr({
      'role': 'tablist',
      'aria-multiselectable': 'true',
    })

    thisSectionFaqQuestions.each(function(i) {
      const $this = $(this)

      // Make first tab clickable
      if (i === 0) {
        $this.attr('tabindex', '0')
      }

      // Click event
      $this.on('click', function() {
        showAnswer($(this), thisSectionFaqQuestions)
      })

      // Keydown event
      $this.on('keydown', function(e) {
        keyboardInteraction($(this), e, thisSectionFaqQuestions)
      })

      // Focus event
      $this.on('focus', function() {
        saveFocus($(this), thisSectionFaqQuestions)
      })
    })
  })

  /**
   * Save question focus
   */
  function saveFocus(elem, thisSectionFaqQuestions) {

    // Reset other tab attributes
    thisSectionFaqQuestions.each(function() {
      $(this).attr('tabindex', '-1')
      $(this).attr('aria-selected', 'false')
    })

    // Set this tab attributes
    elem.attr({
      'tabindex': '0',
      'aria-selected': 'true',
    })

  }

  /**
   * Show answer on click
   */
  function showAnswer(elem, thisSectionFaqQuestions) {
    const thisFaqAnswer = elem.next()

    // Save focus
    saveFocus(elem, thisSectionFaqQuestions)

    // Set this tab attributes
    if (thisFaqAnswer.hasClass('active')) {
      // Hide answer
      thisFaqAnswer.removeClass('active')
      elem.attr('aria-expanded', 'false')
      thisFaqAnswer.attr('aria-hidden', 'true')
    } else {
      if (options.showOneAnswerAtATime) {
        // Hide all answers
        faqAnswer.removeClass('active').attr('aria-hidden', 'true')
        faqQuestion.attr('aria-expanded', 'false')
      }

      // Show answer
      thisFaqAnswer.addClass('active')
      elem.attr('aria-expanded', 'true')
      thisFaqAnswer.attr('aria-hidden', 'false')
    }
  }

  /**
   * Keyboard interaction
   */
  function keyboardInteraction(elem, e, thisSectionFaqQuestions) {
    const keyCode = e.which
    const nextQuestion = elem.next().next().is('dt') ? elem.next().next() : false
    const previousQuestion = elem.prev().prev().is('dt') ? elem.prev().prev() : false
    const firstQuestion = elem.parent().find('dt:first')
    const lastQuestion = elem.parent().find('dt:last')

    switch (keyCode) {
      // Left/Up
      case 37:
      case 38:
        e.preventDefault()
        e.stopPropagation()

        // Check for previous question
        if (!previousQuestion) {
          // No previous, set focus on last question
          lastQuestion.focus()
        } else {
          // Move focus to previous question
          previousQuestion.focus()
        }

        break

      // Right/Down
      case 39:
      case 40:
        e.preventDefault()
        e.stopPropagation()

        // Check for next question
        if (!nextQuestion) {
          // No next, set focus on first question
          firstQuestion.focus()
        } else {
          // Move focus to next question
          nextQuestion.focus()
        }

        break

      // Home
      case 36:
        e.preventDefault()
        e.stopPropagation()

        // Set focus on first question
        firstQuestion.focus()
        break

      // End
      case 35:
        e.preventDefault()
        e.stopPropagation()

        // Set focus on last question
        lastQuestion.focus()
        break

      // Enter/Space
      case 13:
      case 32:
        e.preventDefault()
        e.stopPropagation()

        // Show answer content
        showAnswer(elem, thisSectionFaqQuestions)
        break
    }
  }
}
